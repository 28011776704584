
.flex {
  display: flex;
}

.flex.row {
  flex-direction: row;
}

.flex.column {
  flex-direction: column;
}

.flex.ai-start {
  align-items: flex-start;
}

.flex.ai-center {
  align-items: center;
}

.flex.ai-end {
  align-items: flex-end;
}

.flex.ai-stretch {
  align-items: stretch;
}

.flex.ai-baseline {
  align-items: baseline;
}
  
.flex.jc-start {
  justify-content: flex-start;
}

.flex.jc-center {
  justify-content: center;
}

.flex.jc-end {
  justify-content: flex-end;
}

.flex.jc-around {
  justify-content: space-around;
}

.flex.jc-between {
  justify-content: space-between;
}

.flex.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
  flex-basis: 0%;
}

.flex-grow-2 {
  flex-grow: 2;
  flex-basis: 0%;
}

.flex-grow-3 {
  flex-grow: 3;
  flex-basis: 0%;
}

.flex-grow-4 {
  flex-grow: 4;
  flex-basis: 0%;
}
