.App {
  // width: 100%;
  height: 100%;
  background: #1f1f1f;
  color: #fff;

  .App-header {
    height: 60px;
    line-height: 60px;
    padding: 0 20px;

    .App-product-name {
      font-size: 1.5em;
      font-weight: bold;
      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .App-header-actions {
      padding-right: 20px;

      .App-action-btn {
        margin-left: 20px;
        color: white;
        text-decoration: none;
        padding: 4px 10px;
        cursor: pointer;

        &:hover {
          background: white;
          color: black;
          border-radius: 4px;
          overflow: hidden;
        }
      }
    }
  }

  &.light {
    color: #607D8B;
    background: #fffffe;
  }

  .loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: 20px auto;
  }
  .loader:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #009688 transparent #009688 transparent;
    animation: loader 1.2s linear infinite;
  }
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

}
