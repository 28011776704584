.Editor {
  
  .Editor-header {
    margin: 0 0 20px 0;
    padding: 0 10px 0 30px;

    .Editor-title {
      font-size: 1.1em;
    }

    .Editor-settings {

      .Editor-setting {
        margin-left: 30px;
        .Editor-setting-label {
          margin-right: 10px;
          font-size: 1.2em;
        }
      }
    }
  }

  .Editor-panels-cont {
    padding-left: 10px;
    min-width: 100px;

    .Editor-title {
      font-size: 1.2em;
      font-weight: bold;
    }
    
    .Editor-panel-content {
      margin: 30px 10px;

      &.output-panel {
        overflow: auto;
      }
    }

    .Editor-header-actions {
      margin-right: 5px;
    }

    .action-btn {
      margin-right: 10px;
      padding: 5px 20px;
      cursor: pointer;
      background: #00bcd4;
      border-radius: 4px;
      color: white;

      &.danger {
        background: #F44336;
      }
      
    }


    .Editor-output-result {
      margin: 5px 0;
      pre {
        overflow: auto;
      }
    }
  }

  .select-option {
    cursor: pointer;
    min-width: 120px;

    --styled-select__border-radius: 0px;
    --styled-select-menu-outer__border-radius: 0px;
    --styled-select-menu-outer__margin: 0px;

    .App.dark & {
      --styled-select__background-color: #555;
      --styled-select__border-color: black;
      --styled-select__border-width: 0px;
      --styled-select-placeholder__color: #999;
      --styled-select__color: white;

      --styled-select-menu-outer__background-color: #555;
      --styled-select-menu-outer__border-color: black;
      --styled-select-menu-outer__border-style: solid;
      --styled-select-option__background-color: #444;

      --styled-select-option__color: #eee;

      --styled-select-option__color--focused: #eee;
      --styled-select-option__background-color--focused: #333;

      --styled-select-option__color--selected: #eee;
      --styled-select-option__background-color--selected: #444;
    }
  }

}
